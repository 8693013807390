/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-color-theme {
  --ion-color-base: #01a3a4;
}

.subTitleFont {
  font-size: 18px !important;
  color: var(--ion-color-theme) !important;
}
.ion-padding-20 {
  padding: 20px;
}
.ion-padding-horizontal-20 {
  padding: 0px 20px;
}
.ion-padding-top-5 {
  padding-top: 5px;
}
.ion-padding-end-10 {
  padding-right: 10px;
}
.ion-padding-bottom-5 {
  padding-bottom: 5px;
}
.ion-margin-top-5 {
  margin-top: 5px;
}
.ion-margin-bottom-5{
  margin-bottom: 5px;
}
.ion-margin-bottom-20 {
  margin-bottom: 20px;
}
.ion-margin-top-20 {
  margin-top: 20px;
}
.ion-padding-top-20 {
  padding-top: 20px;
}

.searchbar-input {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-size: 14px !important;
}
.headerFont {
  font-size: 20px;
  color: var(--ion-color-theme) !important;
  padding-top: 18px;
}
.headerBackBtn{
  color: var(--ion-color-theme) !important;
  font-size: 20px;
}
.backgroundColor{
    background: #E1E1E1 !important;
}
.toolbar-container {
  background-color: #F6F7FC !important;
}

.open-summary-update-modal {
  --background: transparent;
  ion-backdrop {
    visibility: visible !important;
  }
  app-summary-update{
    justify-content: center;
  }
  .modal-wrapper {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    top: 50%;
    left: 0;
    position: absolute;
    width: 100%;
    height: 50%;
  }
}
.alert-button {
  color: var(--ion-color-theme) !important;
}

.sc-ion-alert-md-h{
  --max-width:500px !important;
  --width: 100%;
}

ion-content{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}