@import "../../assets/fonts/fonts.scss";

// Fonts
$font-size: 16px;

$font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
$font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
$font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
$font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
$font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;

  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
